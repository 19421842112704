#search-journalist {
    padding-left: 35px;
    padding-top: 20px;
    padding-bottom: 10px;
    border-radius: 5px;
    border: none;
    border-width: 1px;
    background-color: #000;
    color: #fdf8e1;
    font-family: "Roboto";
    font-weight: 600;
    font-size: 14px;
    width: 100%; 
    box-sizing: border-box;
}

.journalists-grid {
    display: flex;
    flex-flow: wrap;
    justify-content: space-evenly;
    align-items: center;
    align-content: flex-start;
    gap: 20px;
    padding-top: 5px;
    overflow-y: auto;
    margin-left: 10px;
    height: 535px;
    margin-bottom: 10px;
}

#search-journalist:focus {
    outline: none;
}

.images-list-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 5px;
}

.images-list {
    overflow-y: auto;
}

.journalist-button {
    text-transform: uppercase;
    border-radius: 10px;
    border-color: #6c757d;
    border-width: 1px;
    font-family: "Roboto";
    background-color: #000;
    color: #FFF;
    width: 140px;
    padding: 0px;
    margin: 0px;
    height: 35px;
    overflow: hidden;
}

.journalist-button.hidden {
    display: none;
}

.journalist-button:hover {
    cursor: pointer;
    border-color: #dee2e6;
    transition: 0.35s;
}

.journalist-button:active {
    background-color: #fff;
    color: #000;
}

.preview-image {
    width: 120px;
    height: 120px;
    border-radius: 10px;
    object-fit:contain;
    margin-left: 10px;
    border: solid;
    border-color: #495057;
    background-color: #000;
    border-width: 1px;
}

.journalist-images-data {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #000;
    border-color: #6c757d;
    border-width:1px;
    border-radius: 10px;
    width: 100%;
}

.journalist-images-data:hover {
    cursor: pointer;
    background-image: radial-gradient(circle, #000 95%, #DDB321);
}

.journalist-images-data:active {
    background-image: none;
    background-color: #DDB321;
    transition-duration: 2s;
}

.journalist-preview-tags-div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    overflow: hidden;
}

.preview-image-tags {
    color: #fff;
    font-weight: bold;
    margin-right: 20px;
    font-family: 'Roboto';
    font-size: 16px;
}

.tags-index {
    color: #fff;
    font-weight: bold;
    margin-right: 20px;
    font-family: 'Roboto';
    font-size: 16px;
    margin-right: 10px;
}