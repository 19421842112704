/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px "#000";
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #DDB321;
}

.MuiDialogTitle-root {
  background-color: #e0bd3b;
}

.pupupButton {
  font-size: 14px;
  font-weight: bold;
  border-color: #adb5bd;
  border-radius: 6px;
  border-style: solid;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
  padding-top: 10px;
  background-color: #f8f9fa;
  text-align: center;
  cursor: pointer;
  margin-bottom: 10px;
}

.pupupButton:hover {
  background-color: #ced4da;
  transition-duration: 0.1s;
}

.pupupButton:active {
  color: #fff;
  background-color: #212529;
}