@media screen and (max-height: 900px) and (min-width: 1250px) {
    .imageNoTags {
        display: flex;
        align-self: center;
        justify-self: center;
        max-width: 100%;
        max-height: 100%;
    }
}

@media screen and (min-width: 1510px) and (min-height: 840px) {
    .imageNoTags {
        display: flex;
        align-self: center;
        justify-self: center;
        max-width: 100%;
        max-height: 100%;
    }
}

@media screen and (max-width: 1509px) {
    .imageNoTags {
        display: flex;
        align-self: center;
        justify-self: center;
        max-width: 100%;
        max-height: 100%;
    }
}

@media screen and (max-width: 1285px) {
    .imageNoTags {
        display: flex;
        align-self: center;
        justify-self: center;
        max-width: 100%;
        max-height: 100%;
    }
}