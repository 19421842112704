.notes-text-area {
    height: 60px;
    padding: 5px;
    font-family: 'Roboto', sans-serif;
    color: #333;
    width: 98.5%;
    border-radius: 10px;
    background-color: #f4f3ee;
}

.notes-text-area:focus {
    outline: none;
}

.notes-text-area::placeholder {
    font-size: 20px;
    transition-duration: 0.25s;
}

.notes-text-area:focus::placeholder {
    font-size: 14px;
    transition-duration: 0.25s;
}