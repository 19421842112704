.infoWrapper {
    color: #FFFFFF;
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: absolute;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

.swiper {
    display: flex;
    max-width: 100%;
    max-height: 100%;
}

.imageNoTags {
    user-select: none;
    -webkit-user-select: none; /* Chrome/Safari */ 
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+ */
}

.swiperSlide {
    display: flex;
    flex-flow: column;
    height: 100%;
    width: 100%;
    justify-content: space-between;
}

.image {
    display: flex;
    align-self: center;
    justify-self: center;
    object-fit:contain;
    height: calc(100% - 120px);
    width: 100%;
}

@media screen and (max-height: 900px) {
    .imageNoTags {
        display: flex;
        align-self: center;
        justify-self: center;
        max-width: 100%;
        max-height: 100%;
    }

    .tagsbar {
        display: flex;
        justify-content: baseline;
        flex-flow: column;
    }
}



@media screen and (min-width: 1510px) {
    .imageNoTags {
        display: flex;
        align-self: center;
        justify-self: center;
        max-width: 100%;
        max-height: 100%;
    }

    .tagsbar {
        display: flex;
        justify-content: baseline;
        flex-flow: column;
    }
}

@media screen and (max-width: 1509px) {
    .imageNoTags {
        display: flex;
        align-self: center;
        justify-self: center;
        max-width: 100%;
        max-height: 100%;
    }

    .swiper {
        width: 760px;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media screen and (max-width: 1300px) {
    .imageNoTags {
        display: flex;
        align-self: center;
        justify-self: center;
        max-width: 100%;
        max-height: 100%;
    }

    .swiper {
        width: 660px;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .swiperSlide {
        display: flex;
        flex-flow: column;
        max-height: 565px;
    }
}